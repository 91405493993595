import React from 'react';

const FormInput = ({ id, label, value, onChange, placeholder, type = 'text' }) => (
  <div className="flex flex-col">
    <label htmlFor={id} className="font-medium">
      {label}
    </label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="p-2 border rounded"
    />
  </div>
);

export default FormInput;
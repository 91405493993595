import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfMake from 'html-to-pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const SaveAsPDF = ({showSavePDFButton }) => {
  const saveAsPDF = () => {
    const element = document.querySelector('.tutorial');
    const banner = document.querySelector('.thebanner');

    let bannerToPass;
    
    if (banner && banner.innerHTML){
      bannerToPass = banner.innerHTML
    } else {
      bannerToPass = ""
    }

   if (!element){
    return
   }
 
    const content = [
      {
        text: 'Photography Inspiration',
        style: 'header',
      },
      {
        text: 'This creative photography prompt was generated for FREE by the Photo Inspirator app!',
        style: 'text',
      },
      {
        text: 'Click HERE to join Photo Mastery Club Insiders to generate 75 Prompts per day!',
        style: 'link',
        link: 'https://ppmastery.com/insiders',
      },
      ...htmlToPdfMake(element.innerHTML),
      {
        text: 'Click HERE for MORE Photography Inspiration',
        style: 'link',
        link: 'https://www.photomastery.app',
      },
    ];

    const docDefinition = {
        content: content,
        pageSize: 'A4',
        pageMargins: [40, 60, 40, 60],
        styles: {
          header: {
            fontSize: 30,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          h1: {
            fontSize: 18,
            bold: true,
          },
          h2: {
            fontSize: 24,
            bold: true,
          },
          h3: {
            fontSize: 16,
            bold: true,
          },
          h4: {
            fontSize: 12,
            bold: true,
          },
          h5: {
            fontSize: 10,
            bold: true,
          },
          h6: {
            fontSize: 8,
            bold: true,
          },
          p: {
            fontSize: 12,
            lineHeight: 1.5,
            color: '#718096', // From text-gray-500
          },
          link: {
            fontSize: 12,
            lineHeight: 1.5,
            color: 'blue',
            decoration: 'underline',
          },
        },
      };
      

    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.getBuffer((buffer) => {
      const blob = new Blob([buffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'photoprompt.pdf';
      link.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    });
  };

  return (
    <button onClick={saveAsPDF} disabled={!showSavePDFButton} className="submit-btn">
      {showSavePDFButton ? "Save As PDF" : "Sign In To Enable Saving To PDF"} 
    </button>
  );
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { getIdToken } from 'firebase/auth';

const WorkflowBanner = ({user, updateContent}) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            const idToken = await getIdToken(user);
            setToken(idToken);
        };
        fetchToken();
    }, [user]);

    const handleClick = async () => {
        try {
            //console.log(token)
            const response = await axios.post('/submit', {}, {
                headers: {
                    'Authorization': token
                },
                withCredentials: true,
            });
            
            //console.log(response.data);
            updateContent('PDF Sent');
        

        } catch (error) {
            console.error(error);
        }
    };
    

    return (
        <div className="flex w-full p-4">
            <div className="w-1/5">
                <img src="https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_200/Photo%20Inspirator/12741.fab272e5ca4b237c5889c61b98934548_qmz3ly.png" alt="Your alt text" className="w-full" />
            </div>
            <div className="w-4/5 pl-4">
                <h3>Download the FREE PDF GUIDE: 6 Step Photoshop Workflow</h3>
                <ul className="list-none list-outside">
                    <li className="flex"><span className="mr-2">✔️</span>Learn the 6-step workflow that makes it EASY to instantly get consistently good results from Photoshop</li>
                    <li className="flex"><span className="mr-2">✔️</span>See how to transform dull, lifeless RAW files into stunning natural-looking landscapes.</li>
                    <li className="flex"><span className="mr-2">✔️</span>Discover some of the simple yet powerful Photoshop techniques that will make your images POP!</li>
                </ul>
                <button onClick={handleClick} className="submit-btn">
                    Click here to recieve the PDF link at your email address
                    <br />
                    and UNLOCK 20 DAILY INSPIRATIONS
                </button>
                <p>* By clicking the above button to download the pdf, you are agreeing to recieve emails from me (Steve Arnold) with free photoshop tips, tutorials, and special offers. You can unsubscribe at any time and I never share your email with anyone, ever.</p>
            </div>
        </div>
    );
}

export default WorkflowBanner;

import React from 'react';

const Disclaimer = ({ onClose }) => {
  return (
    <div className="overlay" onClick={onClose}>
      <div className="popup" style={{ maxHeight: '70vh', overflowY: 'auto' }}> <div>
      <div className="flex justify-end" >
          <button onClick={onClose}>*Close</button>
        </div>
  <h2>Disclaimer:</h2>
  <p>
    The content generated by this app is powered by OpenAI's ChatGPT, an advanced language model. While we strive to provide a useful and engaging experience, please be aware of the following:
  </p>
  <ol>
    <li>
      <strong>User Responsibility:</strong> Users are solely responsible for the prompts and inputs they provide. Please refrain from entering any content that violates OpenAI's usage policies, including but not limited to harmful, offensive, or illegal material.
    </li>
    <li>
      <strong>OpenAI's Usage Policies:</strong> The generated content is subject to OpenAI's terms and conditions. OpenAI has guidelines in place to ensure responsible use of their technology, and it is important to adhere to these guidelines.
    </li>
    <li>
      <strong>Content Moderation:</strong> We have implemented measures to moderate and filter potentially inappropriate or harmful content. However, due to the dynamic nature of language and the limitations of automated systems, we cannot guarantee 100% accuracy in filtering such content. If you come across any problematic outputs, please report them to us immediately.
    </li>
    <li>
      <strong>No Liability:</strong> We do not assume any liability for the content generated by this app. Users are solely responsible for their interactions and usage of the provided information.
    </li>
  </ol>
  <p>
    By using this app, you agree to these terms and acknowledge that the generated content is not guaranteed to be accurate, appropriate, or suitable for any specific purpose. Please exercise caution and use your own judgment while interpreting and relying on the generated responses.
  </p>
</div>

      </div>
    </div>
  );
};

export default Disclaimer;

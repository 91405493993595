import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { SocialShare } from './components/SocialShare';
import { SaveAsPDF } from './components/SaveAsPDF';
import { TutorialComponent } from './components/TutorialComponent';
import FormInput from './components/FormInput';
import DropdownSelect from './components/DropdownSelect';
import Banner from './components/Banner';
import SocialLogin from './components/SocialLogin';
import Disclaimer from './components/Disclaimer';
import AlertMessage from './components/AlertMessage';

import { auth } from './firebase';
import { getIdToken } from 'firebase/auth';



function App() {
  const [genre, setGenre] = useState('Landscapes');
  const [style, setStyle] = useState('Fine Art');
  const [equipment, setEquipment] = useState('');
  const [location, setLocation] = useState('');
  const [otherInfo, setOtherInfo] = useState('');
  const [tutorial, setTutorial] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const [toggleSignIn, setToggleSignIn] = useState(false);
  const [buttonText, setButtonText] = useState('Click here to unlock more inspirations');

  const [ontraportContactStatus, setOntraportContactStatus] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertContent, setAlertContent] = useState('');
  const [userFirstName, setUserFirstName] = useState('');




  if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL_LOCAL;

  } else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL_PRODUCTION;
  }

  //console.log(process.env.REACT_APP_API_URL_LOCAL)


  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };

  const toggleAlertMessage = () => {
    setShowAlertMessage(!showAlertMessage);
  };

  const [showSavePDFButton, setShowSavePDFButton] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);

  const alertError = (theMessage) => {

    alert(theMessage);
  }

 
  const [token, setToken] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      if (user) {
        fetchUsageCount(user.uid);
        setShowSavePDFButton(true);

        if (user.displayName){
          const firstSpaceIndex = user.displayName.indexOf(' ');
        
          // Check if a space exists in the displayName
          if (firstSpaceIndex !== -1) {
            const firstName = user.displayName.substring(0, firstSpaceIndex);
            setUserFirstName(firstName);
          } else {
            setUserFirstName(user.displayName);
          }
        }

        //console.log(user)
        const fetchContactCheckResult = async () => {
          try {
            const fetchedToken = await getIdToken(user);
           // console.log(fetchedToken);
            const response = await axios.get('/contact-check-ontraport', {
              headers: {
                'Authorization': fetchedToken
              },
              withCredentials: true,
            });

            const data = response.data;  // axios
            data.error && alertError(data.error);
            setIsMember(data.isRecurringMember);

            // Set the token in state after making the API call
            setToken(fetchedToken);
           // console.log("check contact: " + fetchedToken);

          } catch (error) {
            console.error(error);
          }
        };

        fetchContactCheckResult();
        // setButtonText(`Logged in: ${userFirstName} (${user.email})`);
      } else {
        setUsageCount(null);
        setShowSavePDFButton(false);
        setButtonText('Click here to unlock more inspirations');
        // clear the token if user is not logged in
        setToken(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setButtonText(`Logged in: ${userFirstName}`);
    setShowAlertMessage(false);
  }, [userFirstName]);


  const [usageCount, setUsageCount] = useState(null);

  async function fetchUsageCount(userId) {
    try {

      const config = {
        headers: {},
        withCredentials: true,
      };

      if (token) {
        config.headers.Authorization = token;
      }
     // console.log("usage count: " + token);

      const response = await axios.get(`/usage-count/${userId}`, config);
      if (response.status >= 200 && response.status < 300) {
        const usageCount = parseInt(response.data, 10);
        // Update the state with the fetched usageCount
        setUsageCount(usageCount);
      } else {
        console.error('Error fetching usage count:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching usage count:', error);
    }
  }


  // Call this function with the user's  ID whenever you want to update the usage count display
  // fetchUsageCount(userId);

  const handleToggleSignIn = () => {
    setToggleSignIn(!toggleSignIn);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setTutorial('');


    try {
      setLoading(true);

      const user = auth.currentUser;

      const config = {
        headers: {},
        withCredentials: true,
      };

      if (token) {
        config.headers.Authorization = token;
      }

      //console.log("generate prompt: " + token);

      const response = await axios.post(
        '/generate-prompt',
        { genre: genre, equipment: equipment, location: location, style: style, otherInfo: otherInfo },
        config
      );
      if (user) {
        fetchUsageCount(user.uid);
        console.log(user.uid + " : " + usageCount);
      }
      setLoading(false);
      setTutorial(response.data.data);
    } catch (error) {
      console.error(error);
      setLoading(false);

      setAlertTitle('Daily Limit Reached!');
      setAlertContent(error.response.data.error);

      setShowAlertMessage(true);
    }
  };



  return (

    <div className="min-h-screen bg-gray-100 flex items-center justify-center">

      <div className="flex flex-col w-full max-w-[50rem]">
        <button className="submit-btn" onClick={handleToggleSignIn}>{buttonText}</button>
        <div className="">
          {currentUser && <p className="ml-4 mb-0">You've generated <strong>{usageCount} inspirations</strong> today!</p>}
          {isMember && <p className="ml-4 p-0">As a Photo Mastery Club Insider, you can generate 75 inspirations per day!</p>}
          <div className="mr-4">
            {toggleSignIn && <SocialLogin currentUser={currentUser} setCurrentUser={setCurrentUser} />}
          </div>

        </div>

        <div className="bg-white shadow-xl rounded-lg p-8 w-full mt-2">

          <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">Photography <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Inspiration</span></h1>

          {/* Add the navigation bar */}
          <ul className="nav-bar">
            <li>
              <a href="#" onClick={toggleDisclaimer}>
                Read Me First
              </a>
            </li>
            <li>
              <a href="https://airtable.com/shrmSK2bfTKBGln9S" target="_blank" rel="noreferrer">Provide Feedback & Suggestions</a>
            </li>
          </ul>


          <form onSubmit={handleSubmit} className="space-y-4">



            <DropdownSelect
              id="genre"
              label="Genre:"
              value={genre}
              onChange={(e) => setGenre(e.target.value)}
              options={[
                { value: 'Street Photography', label: 'Street Photography' },
                { value: 'Landscapes', label: 'Landscapes' },
                { value: 'Seascapes', label: 'Seascapes' },
                { value: 'Cityscapes', label: 'Cityscapes' },
                { value: 'Portraits', label: 'Portraits' },
                { value: 'Pet Photography', label: 'Pet Photography' },
                { value: 'Wildlife', label: 'Wildlife' },
                { value: 'Macro', label: 'Macro' },
                { value: 'Abstract', label: 'Abstract' },
                { value: 'Architectural', label: 'Architectural' },
                { value: 'Night & Astrophotography', label: 'Night & Astrophotography' },
                { value: 'Travel', label: 'Travel' },
                { value: 'Documentary', label: 'Documentary' },
                { value: 'Food', label: 'Food' },
                { value: 'Fashion', label: 'Fashion' },
                { value: 'Sports', label: 'Sports' },
                { value: 'Event', label: 'Event (e.g., weddings, concerts)' },
                { value: 'Aerial & Drone', label: 'Aerial & Drone' },
              ]}
            />


            <DropdownSelect
              id="style"
              label="Style:"
              value={style}
              onChange={(e) => setStyle(e.target.value)}
              options={[
                { value: 'High Dynamic Range (HDR)', label: 'High Dynamic Range (HDR)' },
                { value: 'Black and White', label: 'Black and White' },
                { value: 'Colour', label: 'Colour' },
                { value: 'High-Key', label: 'High-Key' },
                { value: 'Low-Key', label: 'Low-Key' },
                { value: 'Fine Art', label: 'Fine Art' },
                { value: 'Minimalist', label: 'Minimalist' },
                { value: 'Surreal', label: 'Surreal' },
                { value: 'Composite', label: 'Composite' },
              ]}
            />


            <DropdownSelect
              id="equipment"
              label="Equipment:"
              value={equipment}
              onChange={(e) => setEquipment(e.target.value)}
              options={[
                { value: '', label: 'Select' },
                { value: 'Wide-angle lens', label: 'Wide-angle lens' },
                { value: 'Telephoto lens', label: 'Telephoto lens' },
                { value: 'Prime lens (e.g., 35mm, 50mm, 85mm)', label: 'Prime lens (e.g., 35mm, 50mm, 85mm)' },
                { value: 'Macro lens', label: 'Macro lens' },
                { value: 'Zoom lens', label: 'Zoom lens' },
                { value: 'External flash', label: 'External flash' },
                { value: 'ND filters', label: 'ND filters' },
                { value: 'Drone', label: 'Drone' },
              ]}
            />


            <FormInput
              id="location"
              label="Location: (This works BEST when entering a location!)"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder=""
            />

            <FormInput
              id="otherInfo"
              label="Other Information:"
              value={otherInfo}
              onChange={(e) => setOtherInfo(e.target.value)}
              placeholder=""
            />

            <button
              type="submit"
              className={loading ? "submit-btn-loading" : "submit-btn"}
              disabled={loading}
            >
              {loading ? (
                <>
                  Loading
                  <span className="dot1"></span>
                  <span className="dot2"></span>
                  <span className="dot3"></span>
                </>
              ) : (
                "Generate Creative Photo Prompt"
              )}
            </button>

          </form>

          {tutorial && (
            <>
              <div className="tutorial mt-8 p-4 bg-gray-100 rounded">
                <TutorialComponent tutorial={tutorial} />
                {tutorial && <SocialShare />}
              </div>

              {!isMember && <Banner genre={style} firstName={userFirstName}/>}


            </>
          )}




          <SaveAsPDF showSavePDFButton={showSavePDFButton} />
          {showDisclaimer && <Disclaimer onClose={toggleDisclaimer} />}
          {showAlertMessage && <AlertMessage
            onCloseAlert={toggleAlertMessage}
            title={alertTitle}
            content={alertContent}
            user={currentUser}
          />}


        </div>
      </div>
    </div>
  );
}

export default App;


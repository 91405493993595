import React, { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';


const SocialLogin = ({ currentUser, setCurrentUser }) => {
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [signUpFirstName, setSignUpFirstName] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [showSignInForm, setShowSignInForm] = useState(true);
  const [signInOrUp, setSignInOrUp] = useState('in');

  const handleToggleForm = () => {
    setShowSignInForm(!showSignInForm);
    setErrorMessage('');
  };

  useEffect( () => {

    if (showSignInForm){
      setSignInOrUp('in');
    } else {
      setSignInOrUp('up');
    }

  }, [showSignInForm])

 
 const handleResetPassword = async () => {
  if (signInEmail === '') {
    setErrorMessage('Please enter your email address in the email field before clicking "Forgot Password?"');
    return;
  }

  try {
    await sendPasswordResetEmail(auth, signInEmail);
    setErrorMessage('A password reset email has been sent to your email address.');
  } catch (error) {
    console.log(error);
  }
};

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      //console.log(result)
      // Handle successful login
      setErrorMessage('')
    } catch (error) {
      console.log(error)
    }
  };

  const getUserFirstName = async (uid) => {
    const db = getFirestore();
    const userRef = doc(db, 'users', uid);
    const userSnapshot = await getDoc(userRef);
  
    if (userSnapshot.exists()) {
      return userSnapshot.data().displayName;
    } else {
      console.log('User not found in Firestore');
      return null;
    }
  };
  


  const setCurrentUserWithFirstName = useCallback(async (user) => {
    if (user) {
      // Check if the user logged in with email
      if (!user.displayName && user.providerData[0].providerId === 'password') {
        // Fetch the first name from Firestore for email logins
        const firstName = await getUserFirstName(user.uid);
        setCurrentUser({ ...user, firstName });
      } else {
        setCurrentUser(user);
      }
    } else {
      setCurrentUser(null);
    }
  }, [setCurrentUser]);

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUserWithFirstName(user);
    });
  
    return () => unsubscribe();
  }, [setCurrentUserWithFirstName]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, signInEmail, signInPassword);
      // Only allow users with verified email addresses to log in
      if (userCredential.user.emailVerified) {
        console.log('User Found');
      } else {
        setErrorMessage('* Please verify your email address before logging in.');
        await signOut(auth);
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          setErrorMessage('* The email address is not valid.');
          break;
        case 'auth/user-disabled':
          setErrorMessage('* The user account has been disabled.');
          break;
        case 'auth/user-not-found':
          setErrorMessage('* The login information you have provided is incorrect');
          break;
        case 'auth/wrong-password':
          setErrorMessage('* The login information you have provided is incorrect');
          break;
        case 'auth/too-many-requests':
            setErrorMessage('* Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.');
            break;
        default:
          setErrorMessage('* An unknown error occurred. Please try again.');
      }
      console.log(error);
    }
  };

  
  const handleEmailSignUp = async (e) => {
    e.preventDefault();
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, signUpEmail, signUpPassword);
      // Set display name in user profile
      await updateProfile(userCredential.user, {
        displayName: signUpFirstName,
      });
      // Send verification email
      await sendEmailVerification(userCredential.user);
      setErrorMessage('A verification email has been sent to your email address.');
  
      // Handle successful email sign-up
      const db = getFirestore();
      const usersRef = doc(db, 'users', userCredential.user.uid);
      
      await setDoc(usersRef, {
        displayName: signUpFirstName,
      });

  // Sign out the user after sending the verification email
    await signOut(auth);
  
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Handle successful logout
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
{!currentUser && <div className="flex flex-col items-center justify-center h-full">
  <h2>Sign In</h2>
  <p>Sign in with a FREE ACCOUNT and unlock the ability to generate 10 inspirations per day!</p>
  
</div>}


    <div className="flex items-center justify-center h-full">       
      <div className="flex flex-col justify-start w-1/2 p-2">
        
      {!currentUser && 
      <><h3 className='p-3'>Sign in with Social:</h3>
        <button
          onClick={handleGoogleLogin}
          className="w-[220px] bg-white text-gray-600 border border-gray-300 px-4 py-2 rounded font-medium shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center w-full mb-4"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            alt="G"
            width="18"
            height="18"
            className="mr-2"
          />
          Sign in with Google
        </button></>
        }
        </div>
        <div className="flex w-1/2 p-2 flex-col">
       
        {showSignInForm  && !currentUser && (
          <>
           <h3 className='p-3'>Sign {signInOrUp} with Email:</h3>
          <form onSubmit={handleEmailLogin}>
            <input
              type="email"
              placeholder="Email"
              value={signInEmail}
              onChange={(e) => setSignInEmail(e.target.value)}
              className="border border-gray-300 px-3 py-2 rounded mb-2 w-full"
            />
            <input
              type="password"
              placeholder="Password"
              value={signInPassword}
              onChange={(e) => setSignInPassword(e.target.value)}
              className="border border-gray-300 px-3 py-2 rounded mb-2 w-full"
            />
            <button
              type="submit"
              className="bg-white text-gray-600 border border-gray-300 px-4 py-2 rounded font-medium shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center w-full"
            >
              Sign in with Email
            </button>
            <p className="text-gray-600 mb-0 py-1">
        <button className="text-blue-500 focus:outline-none" onClick={handleResetPassword}>
          Forgot Password?
        </button>
      </p>
           
            <p className="text-gray-600  mb-0 py-1">
              Don't have an account?{' '}
              <button className="text-blue-500 focus:outline-none" onClick={handleToggleForm}>
                Register now
              </button>
            </p>
            <p className='error-message  mb-0 py-1'>{errorMessage}</p>

          </form></>
        ) }
        {!showSignInForm  && !currentUser && (
          <form onSubmit={handleEmailSignUp}>
            <input
              type="text"
              placeholder="First Name"
              value={signUpFirstName}
              onChange={(e) => setSignUpFirstName(e.target.value)}
              className="border border-gray-300 px-3 py-2 rounded mb-2 w-full"
            />
            <input
              type="email"
              placeholder="Email"
              value={signUpEmail}
              onChange={(e) => setSignUpEmail(e.target.value)}
              className="border border-gray-300 px-3 py-2 rounded mb-2 w-full"
            />
            <input
              type="password"
              placeholder="Password"
              value={signUpPassword}
              onChange={(e) => setSignUpPassword(e.target.value)}
              className="border border-gray-300 px-3 py-2 rounded mb-2 w-full"
              />
              <button
                type="submit"
                className="bg-white text-gray-600 border border-gray-300 px-4 py-2 rounded font-medium shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center w-full"
              >
                Sign up with Email
              </button>
              <p className="text-gray-600 mt-2">
                Already have an account?{' '}
                <button className="text-blue-500 focus:outline-none" onClick={handleToggleForm}>
                  Sign in
                </button>
              </p>

            </form>
          )}

        </div>
        
      </div>
      {!currentUser && <p>* by signing in you agree to receive emails from Photo Mastery Club (you can unsubscribe any time)</p>}
      {currentUser &&
      <div className="flex items-center justify-end h-full">
            <button
              onClick={handleLogout}
              className="bg-blue-600 text-white px-4 py-2 rounded font-medium shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Sign out
            </button>
            </div>
            }
<ul className="nav-bar">
            <li>
              <a href="https://photomasteryclub.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
            </li>
          </ul>
   </> );
  
};

export default SocialLogin;

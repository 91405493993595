import React from 'react';

const DropdownSelect = ({ id, label, value, onChange, options }) => (
  <div className="flex flex-col">
    <label htmlFor={id} className="font-medium">
      {label}
    </label>
    <select id={id} value={value} onChange={onChange} className="p-2 border rounded">
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default DropdownSelect;

import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';

export const SocialShare = () => {
  const shareUrl = window.location.href;
  const title = `Have you seen this free "creative photo prompts" app? It's pretty cool! Type in a location and get ideas on what and how to photograph there...`;

  return (
    <div className="flex items-center pt-2.5">
      <h3 className="mr-2">Share This:</h3>
      <FacebookShareButton url={shareUrl} quote={title} imageUrl="https://cdn.discordapp.com/attachments/1085411205474160680/1114464177499672666/Arnolio_a_colour_photo_in_a_minimalist_documentary_photography__68de46c7-8db3-4b13-b3f1-f2fe3cfbff60.png" className="mr-2">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );
};

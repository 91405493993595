import React, { useState } from 'react';
import WorkflowBanner from './WorkflowBanner';
import SocialLogin from './SocialLogin';

const ContentRenderer = ({ content, user, updateContent, setCurrentUser }) => {
  switch (content) {
    case "3":
      return (<>
              <h4>You have generated {content} inspirations today!</h4>
              <SocialLogin currentUser={user} setCurrentUser={setCurrentUser}/>
              </>
      );
    case "10":
      return (<>
              <h4>You have generated {content} inspirations today!</h4>
              <h3>Increase your daily limit to 20 inspirations for FREE, by downloading our free guide:</h3>
              <WorkflowBanner user={user} updateContent={updateContent}/>
              </>
      );
      case "20":
        return (<>
        
                <h3>Increase your daily limit to 75 inspirations!</h3>
                <p>Become a Photo Mastery Club Insider and receive:</p>
                <ul className="list-none list-outside">
                <li className="flex"><span className="mr-2">✔️</span>Brand new Photoshop tutorials and lessons every month</li>
                <li className="flex"><span className="mr-2">✔️</span>Monthly Live Zoom sessions</li>
                <li className="flex"><span className="mr-2">✔️</span>Our 50+ Page Photo Editing Workflow Guide</li>
                <li className="flex"><span className="mr-2">✔️</span>75 Daily Inspirational Photo Prompts!</li>
                </ul>
                <a href="https://ppmastery.com/insiders?utm_campaign=Inspirator&utm_source=Inspirator" target="_blank">
                <button className="submit-btn">Click Here Now to Become An Insider</button>
                </a>
                </>
        );
    case "PDF Sent":
      return (
        <>
        <h2>Great Success!</h2>
        <h4>The PDF has been sent to your email address</h4>
        <p>Go read it now to unlock your extra daily inspirations!</p>
        </>

      )
    // Add more cases as needed
    default:
      return <p>{content}</p>;
  }
}

const AlertMessage = ({ onCloseAlert, title, content, user, setCurrentUser }) => {

  const [currentContent, setCurrentContent] = useState(content);
  const [currentTitle, setCurrentTitle] = useState(title);

  const updateContent = (newContent) => {
    setCurrentContent(newContent);
    setCurrentTitle("");
  };

  return (
    <div className="overlay" onClick={onCloseAlert}>
      <div className="popup" onClick={(event) => event.stopPropagation()} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <div className="flex justify-end" >
          <button onClick={onCloseAlert}>*Close</button>
        </div>
        <div>
          <h2>{currentTitle}</h2>
          <ContentRenderer
            content={currentContent}
            user={user}
            updateContent={updateContent}
            setCurrentUser={setCurrentUser}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;

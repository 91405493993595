import React, { useState, useEffect } from 'react';

const Banner = ({ genre , firstName}) => {


  //const [imageHeight, setImageHeight] = useState(0);

  const getBannerContent = (genre) => {
    switch (genre) {
      case 'Black and White':
        return {
          imageUrls: [
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/Example_1_bbvsjf.jpg',
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/Example_2_xlqlmk.jpg', // Replace with your actual image URLs
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/Example_3_bcuuse.jpg',
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/Example_4_rdslt1.jpg', // Replace with your actual image URLs
          ],
          headline: 'Master Creative B&W Editing in Photoshop with this:',
          text: `Creating great-looking black and white images is about more than just removing colour via "desaturate", or moving a few sliders up and down with a black and white adjustment layer. It's about light, and what you do with it.`,
          link: 'https://ppmastery.com/bw-inspire?utm_campaign=Inspirator&utm_source=Inspirator',
          buttonText: 'Click HERE to master Black & White editing',
        };
      case 'Colourx':
        return {
          imageUrls: [
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/12741.b24ee16337cfcfef165199b37a2e12af_vssdkr.jpg',
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/12741.3ef451db3cc9e05932050473ad9d27b7_bzrqow.jpg', // Replace with your actual image URLs
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_700/Photo%20Inspirator/12741.b2f75b7bae02096db3d5fffed70a2ed3_c3zuuz.jpg', // Replace with your actual image URLs
          ],
          headline: `Here's how to SOLVE the problem of Flat-Looking Landscapes in Photoshop:`,
          text: 'If you want to discover the concepts and ideas that turn a flat-looking landscape into something you feel like you could step right into, then check out the Dynamic Landscape Editing Toolkit now!',
          link: 'https://ppmastery.com/dynamic-inspire?utm_campaign=Inspirator&utm_source=Inspirator',
          buttonText: 'Click HERE to learn more',
        };
        case 'High-Keyx':
          return {
            imageUrls: [
              '../images/bear.png',
              '../images/person.png', // Replace with your actual image URLs
              '../images/people.png', // Replace with your actual image URLs
            ],
            headline: 'Explore Landscape Photography',
            text: 'Text goes here!!',
            link: 'https://example.com/landscape-photography',
            buttonText: 'Click HERE to learn more',
          };
          case 'Low-Keyx':
            return {
              imageUrls: [
                'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/Example_1_bbvsjf.jpg',
                'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/Example_2_xlqlmk.jpg', // Replace with your actual image URLs
                'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/Example_3_bcuuse.jpg',
                'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/Example_4_rdslt1.jpg', // Replace with your actual image URLs
              ],
              headline: 'Master Creative B&W Editing in Photoshop with THIS:',
              text: `Creating great-looking black and white images is about more than just removing colour via "desaturate", or moving a few sliders up and down with a black and white adjustment layer. It's about light, and what you do with it.`,
              link: 'https://ppmastery.com/bw-inspire?utm_campaign=Inspirator&utm_source=Inspirator',
              buttonText: 'Click HERE to learn more',
            };
            case 'Fine Artx':
              return {
                imageUrls: [
                  'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.b24ee16337cfcfef165199b37a2e12af_vssdkr.jpg',
                  'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.3ef451db3cc9e05932050473ad9d27b7_bzrqow.jpg', // Replace with your actual image URLs
                  'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.b2f75b7bae02096db3d5fffed70a2ed3_c3zuuz.jpg', // Replace with your actual image URLs
                ],
                headline: `Here's how to SOLVE the problem of Flat-Looking Landscapes in Photoshop:`,
                text: 'If you want to discover the concepts and ideas that turn a flat-looking landscape into something you feel like you could step right into, then check out the Dynamic Landscape Editing Toolkit now!',
                link: 'https://ppmastery.com/dynamic-inspire?utm_campaign=Inspirator&utm_source=Inspirator',
                buttonText: 'Click HERE to learn more',
              };
              case 'Minimalistx':
                return {
                  imageUrls: [
                    '../images/bear.png',
                    '../images/person.png', // Replace with your actual image URLs
                    '../images/people.png', // Replace with your actual image URLs
                  ],
                  headline: 'Learn Photoshop from the ground UP',
                  text: 'Photoshop 101',
                  link: 'https://example.com/landscape-photography',
                  buttonText: 'Click HERE to learn more',
                };
                case 'Surrealx':
                  return {
                    imageUrls: [
                      '../images/bear.png',
                      '../images/person.png', // Replace with your actual image URLs
                      '../images/people.png', // Replace with your actual image URLs
                    ],
                    headline: 'Learn how to create surreal composites in Photoshop',
                    text: 'Text goes here!',
                    link: 'https://example.com/landscape-photography',
                    buttonText: 'Click HERE to learn more',
                  };
                  case 'Compositex':
                    return {
                      imageUrls: [
                        'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.33b7fb8e157272b8d06f2ddc77ec7d12_xlt0vl.jpg',
                        'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.f7d3e542ac8da2632a37515ed0aaaa57_l3fz5h.jpg', // Replace with your actual image URLs
                        'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.fb5c2266f073cd9c2fb839b8ab9bdfb9_nusm13.jpg', // Replace with your actual image URLs
                      ],
                      headline: 'Want to learn how to create amazing composites in Photoshop?',
                      text: 'Unleash Your Creative Freedom With The Editing Techniques That Give You The Power To Bend Photographic Reality To Your Every Will And Desire In Photoshop.',
                      link: 'https://ppmastery.com/nextlevelcompositelandscapes?utm_campaign=Inspirator&utm_source=Inspirator',
                      buttonText: 'Click HERE to learn more',
                    };
                  case 'High Dynamic Range (HDR)x':
                    return {
                      imageUrls: [
                        'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.fd508b89cbfba17792df923079546aef_z6jj9s.jpg',
                        'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.59dc9254c3406d8d44316ef1a9c6dcbb_znneju.jpg', // Replace with your actual image URLs
                        'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.32726268beed9e431c809dd1173f31cd_vwoh6j.jpg', // Replace with your actual image URLs
                      ],
                      headline: 'Master Luminosity Masking for the ULTIMATE Control over your Landscape Editing:',
                      text: `Master the Photoshop skills you need to inject your landscapes with the WOW factor that they've been missing until now.`,
                      link: 'https://ppmastery.com/lmm3?utm_campaign=Inspirator&utm_source=Inspirator',
                      buttonText: 'Click HERE to learn more',
                    };
      // Add more cases for other genres and their respective banner contents
      default:
        return {
          imageUrls: [
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.b24ee16337cfcfef165199b37a2e12af_vssdkr.jpg',
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.3ef451db3cc9e05932050473ad9d27b7_bzrqow.jpg', // Replace with your actual image URLs
            'https://res.cloudinary.com/dpoebvtz1/image/upload/c_scale,w_800/Photo%20Inspirator/12741.b2f75b7bae02096db3d5fffed70a2ed3_c3zuuz.jpg', // Replace with your actual image URLs
          ],
          headline: 'Discover The Secret To Making Your Landscapes Truly POP',
          text: 'If you want to discover the concepts and ideas that turn a flat-looking landscape into something you feel like you could step right into, then download our FREE GUIDE NOW!',
          link: 'https://dynamiclandscapeguide.com?utm_campaign=Inspirator&utm_source=Inspirator',
          buttonText: 'Click HERE to download our FREE GUIDE',
        };
    }
  };

  const { imageUrls, headline, text, link, buttonText } = getBannerContent(genre);
  const [currentImageUrlIndex, setCurrentImageUrlIndex] = useState(0);
  const [imageUrlsData, setImageUrlsData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageUrlIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [imageUrls.length]);

  useEffect(() => {
    imageUrls.forEach((imageurl, index) => {
      if (imageurl.startsWith('data:image/')) {
        setImageUrlsData((prevUrlsData) => {
          const newUrlsData = [...prevUrlsData];
          newUrlsData[index] = imageurl;
          return newUrlsData;
        });
        return;
      }
  
      fetch(imageurl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = function() {
          setImageUrlsData((prevUrlsData) => {
            const newUrlsData = [...prevUrlsData];
            newUrlsData[index] = reader.result;  // this will be a Base64 string
            return newUrlsData;
          });
        }
        reader.readAsDataURL(blob);
      });
  });
}, [genre, imageUrls]);

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
    //  setImageHeight(this.height);
    };
    img.src = imageUrlsData[currentImageUrlIndex];
  }, [currentImageUrlIndex, imageUrlsData]);
  
  return (
    <div className="tutorial thebanner mt-8 p-4 bg-gray-100 rounded">
      <h2 className="text-xl font-bold">{firstName}: {headline}</h2>
      <a href={link} target="_blank" rel="noreferrer">
        <button className="submit-btn-warm" >
            {buttonText}
            </button>
          </a>
      <div className="flex flex-col">
        <div className="w-full relative" style={{ paddingBottom: '66.66%' }}> {/* this 56.25% gives you 16:9 aspect ratio, adjust according to your needs */}
          {imageUrlsData.map((urlData, index) => (
            <a key={index} href={link} target="_blank" rel="noreferrer">
            <img
              key={index}
              src={urlData}
              alt={headline}
              className={`w-full h-full object-contain absolute top-0 left-0 transition-opacity duration-1000 ease-in-out ${currentImageUrlIndex === index ? 'opacity-100' : 'opacity-0'
                }`}
            />
            </a>
          ))}
        </div>
        <div className="pl-4">
          <p className="mb-4">{text}</p>

        </div>
      </div>
    </div>
  );
  

};

export default Banner;
